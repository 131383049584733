/*
 * @Date: 2021-05-31 19:56:24
 * @LastEditors: Murphy
 * @LastEditTime: 2021-06-06 16:37:42
 */
import request from '@/network/request.js'

/**
 *
 * 个人信息
 *
 */
export function srueCustomeDataBox(data) {
  return request({
    url: '/offerSystem/home/user/userMsg/changeUserMsg',
    method: 'post',
    data,
  })
}
